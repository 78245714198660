import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import Splide from '@splidejs/splide/dist/js/splide';
import 'hc-sticky/dist/hc-sticky';
import 'lazysizes';


$(document).foundation();




(function ($) {

    var direction = $('html').css("direction").toLowerCase();
    var direction_opposite = $('html').css("direction").toLowerCase() === 'rtl' ? 'left' : 'right';
    var direction_boolean = $('html').css("direction").toLowerCase() === 'rtl';

    var engineUtils = {

        init: function () {
            this.ui.init();
        },

        ui: {

            init: function () {
                engineUtils.ui.layout();
                engineUtils.ui.plugins();
            },

            layout: function () {

                $.fn.isInViewport = function () {

                    var elementTop = $(this).offset().top;

                    var elementBottom = elementTop + $(this).outerHeight() / 2;

                    var viewportTop = $(window).scrollTop();

                    var viewportHalf = viewportTop + $(window).height() / 2;

                    return elementBottom > viewportTop && elementTop < viewportHalf;

                };

                // menu toggle for mobile
                if ($('.main-nav').length) {
                    $(".navpanel").hide();
                    $('#menuToggle').on('click', function () {
                        $("body").addClass("menu-open");
                        $(".navpanel").animate({
                            width: "toggle"
                        });
                    });

                    $("#closeMenu").on("click", function () {
                        $("body").removeClass("menu-open");
                        $(".navpanel").animate({
                            width: "toggle"
                        });
                    });


                    $('.main-nav__items').on('click', 'a', function () {
                        $('.main-nav__items a.active').removeClass('active');
                        $(this).addClass('active');
                    });


                    $(window).on('scroll', function () {
                        $(".main-nav__item a").removeClass("active");

                        $('.section').each(function () {

                            if ($(this).isInViewport()) {
                                let sectionId = "#" + $(this).attr("id");
                                $(".main-nav__item a[href='" + sectionId + "']").addClass("active");

                            } else {

                            }

                        });

                    });
                }


                // CARDS from Hiba
                if ($('.card').length) {
                    // Optional: Get all the cards on the page
                    var cards = document.querySelectorAll('.card');

                    // Add click event listener to each card
                    cards.forEach(function (card) {
                        card.addEventListener('click', function () {
                            this.querySelector('.details').style.display = 'flex';
                        });
                    });
                }

                $(window).on("load resize", function () {
                    if (Foundation.MediaQuery.is('large') == true) {

                        let windowView = $(window).height() - $(".main-header").height();
                        $(".section-hero").css("height", windowView + "px");

                    }
                });
            },

            plugins: function () {
                // Plugins Here!!!    

                if ($('#sticky-header').length > 0) {
                    new hcSticky('#sticky-header', {
                        stickTo: 'body'
                    });
                }

                if ($('#hero').length) {
                    var heroSplide = new Splide('#hero', {
                        type: 'fade',
                        arrows: false
                    }).mount();

                    let items = $("#hero .splide__slide").length; //Amount of items 

                    if (items <= 1) { //If amount of items is only 1
                        heroSplide.options = {
                            pagination: false,
                        };
                    }
                }

                if ($('#sideTabs').length) {
                    var sideTabssplide = new Splide('#sideTabs', {
                        pagination: false,
                        arrows: false,
                        direction: direction,
                        drag: false
                    });

                    var sideTabsthumbnails = document.getElementsByClassName('accordion-title');
                    var sideTabscurrent;

                    for (var i = 0; i < sideTabsthumbnails.length; i++) {
                        initThumbnail(sideTabsthumbnails[i], i);
                    }

                    function initThumbnail(sideTabsthumbnail, index) {
                        sideTabsthumbnail.addEventListener('click', function () {
                            sideTabssplide.go(index);
                        });
                    }

                    sideTabssplide.on('mounted move', function () {
                        var sideTabsthumbnail = sideTabsthumbnails[sideTabssplide.index];

                        if (sideTabsthumbnail) {
                            if (sideTabscurrent) {
                                sideTabscurrent.classList.remove('is-active');
                            }


                            sideTabsthumbnail.classList.add('is-active');
                            sideTabscurrent = sideTabsthumbnail;
                        }
                    });

                    sideTabssplide.mount();
                }

                if ($('#topTabs').length) {
                     
                    var topTabssplide = new Splide('#topTabs', {
                        pagination: false,
                        arrows: true,
                        direction: $('html').css("direction").toLowerCase()
                    });

                    var topTabsthumbnails = new Splide('#thumbnailsTopTabs', {
                        arrow: false,
                        pagination: false,
                        isNavigation: true,
                        fixedWidth: 282,
                        fixedHeight: 100,
                        direction: $('html').css("direction").toLowerCase(),
                        breakpoints: {
                            860: {
                                fixedWidth: 171,
                                fixedHeight: 60,
                                gap: 23
                            },
                            600: {
                                fixedWidth: 171,
                                fixedHeight: 60,
                                gap: 23
                            },
                            396: {
                                fixedWidth: 163,
                                fixedHeight: 60,
                                gap: 16
                            },
                        },
                    });

                    topTabssplide.sync(topTabsthumbnails);
                    topTabssplide.mount();
                    topTabsthumbnails.mount();
                }

                if ($('#spotlightGallery').length) {
                    var spotlightGallerysplide = new Splide('#spotlightGallery', {
                        //  type: 'fade',
                        rewind: false,
                        pagination: false,
                        arrows: true,
                        direction: direction
                    });

                    var spotlightthumbnailsplide = new Splide('#spotlightThumbnails', {
                        arrow: false,
                        pagination: false,
                        isNavigation: true,
                        fixedWidth: 80,
                        fixedHeight: 80,
                        gap: 24,
                        direction: direction,
                        breakpoints: {
                            600: {
                                fixedWidth: 56,
                                fixedHeight: 56,
                                gap: 16
                            },
                            396: {
                                fixedWidth: 56,
                                fixedHeight: 56,
                                gap: 16
                            },
                        },
                    });

                    spotlightGallerysplide.sync(spotlightthumbnailsplide);
                    spotlightGallerysplide.mount();
                    spotlightthumbnailsplide.mount();
                }
            }

        }

    };

    $(document).ready(function () { engineUtils.init(); });


})(jQuery);



